import classNames from "classnames";
import { Button } from "components/button/Button.component";
import { Link } from "components/link/Link.component";
import React from "react";

export const ButtonLink = ({
  className,
  children,
  newTab,
  to,
  onClick,
  icon,
  hoverAnimation,
  variant,
}) => {
  return (
    <Link to={to} newTab={newTab} onClick={onClick} className="no-underline">
      <Button
        tabIndex={-1}
        icon={icon}
        hoverAnimation={hoverAnimation}
        className={classNames([className, "no-margin"])}
        variant={variant}
      >
        {children}
      </Button>
    </Link>
  );
};
