import { ButtonLink } from "components/button-link/ButtonLink.component";
import React from "react";
import linksService from "src/services/links.service";
import { ButtonVariant } from "../../../../components/button/types";
import {
  CUSTOM,
  FIVE_HUNDRED,
  HUNDRED,
  THOUSAND,
  TWO_HUNDRED_FIFTY,
} from "./constants";

export const DonationsBody = () => {
  const donationButtons = [
    {
      label: HUNDRED,
      link: linksService.donate100(),
      buttonVariant: ButtonVariant.SECONDARY,
    },
    {
      label: TWO_HUNDRED_FIFTY,
      link: linksService.donate250(),
      buttonVariant: ButtonVariant.SECONDARY,
    },
    {
      label: FIVE_HUNDRED,
      link: linksService.donate500(),
      buttonVariant: ButtonVariant.SECONDARY,
    },
    {
      label: THOUSAND,
      link: linksService.donate1000(),
      buttonVariant: ButtonVariant.SECONDARY,
    },
    {
      label: CUSTOM,
      link: linksService.donateOther(),
      buttonVariant: ButtonVariant.PRIMARY,
    },
  ];

  return (
    <section>
      <div className="side-body">
        <h3>Student Opportunity Fund</h3>
        <div className="donation-button-link-wrapper">
          {donationButtons.map(({ label, link, buttonVariant }) => {
            return (
              <ButtonLink
                className="md"
                to={link}
                variant={buttonVariant}
                newTab
              >
                {label}
              </ButtonLink>
            );
          })}
        </div>
        <div className="mission-vision">
          <p
            style={{
              lineHeight: "1.5rem",
              maxWidth: "600px",
            }}
          >
            We appreciate the generosity of our friends and families in
            supporting PacBay and our goals. This year we are focusing on the
            Student Opportunity Fund initiative. We desire to enhance our
            educational programs and give more students the opportunity to learn
            from and alongside the dynamic educators and thoughtful students in
            our community.
          </p>
          <p
            style={{
              lineHeight: "1.5rem",
              maxWidth: "600px",
            }}
          >
            To continue to provide a high quality Christian education in one of
            the most vital regions of the world, we ask that you prayerfully
            consider partnering with us.
          </p>
          <h4>Your contributions allow us to:</h4>
          <ul>
            <li style={{ margin: "0.5rem" }}>
              Expand our inclusive, Christ-centered community
            </li>
            <li style={{ margin: "0.5rem" }}>
              Further enhance our course offerings
            </li>
            <li style={{ margin: "0.5rem" }}>
              Continue to prepare future leaders
            </li>
            <li style={{ margin: "0.5rem" }}>
              Provide additional classroom resources
            </li>
            <li style={{ margin: "0.5rem" }}>
              Offer a wide array of extracurricular activities
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
