import React, { useEffect } from "react";
import { DonationOptions } from "./components/donation-options/DonationOptions.component";
import { DonationsBody } from "./components/donations-body/DonationsBody.component";
import { DONATION_HEADING } from "./constants";
import "./styles.scss";

export const Donations = () => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div className="donations fix-header-overlap">
      <aside className="side-background">
        <div className="overlay" />
        <h2 className="heading invert-color no-margin">{DONATION_HEADING}</h2>
      </aside>
      <main style={{ padding: "2.5rem", overflowY: "auto" }}>
        <DonationsBody />
        <DonationOptions />
      </main>
    </div>
  );
};
