import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import React from "react";
import { Bubble } from "./components/bubble/Bubble.component";
import {
  CHECK_SUBTEXT,
  CHECK_TEXT,
  GIVE_HEADING,
} from "./DonationOptions.constants";
import "./DonationOptions.styles.scss";

export const DonationOptions = () => {
  return (
    <>
      <h4>{GIVE_HEADING}</h4>
      <div className="bubble-wrapper">
        <Bubble
          icon={SVGIcons.Payments}
          label={CHECK_TEXT}
          sublabel={CHECK_SUBTEXT}
        />
      </div>
    </>
  );
};
