import { SEO } from "components/seo/SEO.component";
import React from "react";
import { Donations as DonationsModule } from "../modules/donations/Donations.component";

const Donations = () => {
  return (
    <>
      <SEO title="Donations" />
      <DonationsModule />
    </>
  );
};

export default Donations;
