import classNames from "classnames";
import { Link } from "components/link/Link.component";
import { SvgWrapper } from "components/svg-wrapper/SvgWrapper.component";
import React from "react";
import "./Bubble.styles.scss";

export const Bubble = ({ icon, label, sublabel, link, children }) => {
  const BubbleWithProps = () => {
    return (
      <div
        className={classNames(["bubble"], {
          "has-link": Boolean(link),
        })}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon && <SvgWrapper iconType={icon} width="2rem" height="2rem" />}
          {label && <p style={{ marginLeft: "0.25rem" }}>{label}</p>}
        </div>
        {children}
        {sublabel && (
          <div
            style={{
              fontSize: "0.75rem",
              marginTop: "0.5rem",
              textAlign: "center",
              width: "75%",
            }}
          >
            {sublabel}
          </div>
        )}
      </div>
    );
  };

  return link ? (
    <Link
      to={link}
      newTab
      className="show-link-icon"
      style={{ color: "var(--black)" }}
    >
      <BubbleWithProps />
    </Link>
  ) : (
    <BubbleWithProps />
  );
};
